
























































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class BloodGroup extends Vue {
public fields = [{key: 'title', sortable: true }, {key: 'actions', label: 'Actions'}];
    loading = true;
    filter = null;
    filterOn = [];
    error_mesg = null;
  public items= [];
  private bloodgroup: any = { title: ""};

public currentPage = 1;
get rows(){return this.items.length;}
  
  retrieve() {
    this.loading = true;
    BuiltinService.getbloodgroup().then((response) => {
        this.items = response.data;
        this.loading = false;
    })
      .catch((e) => {
        this.loading = false;
      });
  }

  handleSubmit(){

    this.create();
  }
  create() {
    this.loading = true;
     this.error_mesg = null;
    if(this.bloodgroup['id'] != null){
        BuiltinService.putbloodgroup(this.bloodgroup['id'], this.bloodgroup)
      .then((response) => {
        if(response){
          this.retrieve();
          this.bloodgroup = {title : ""}
              this.loading = false;
        }
      })
      .catch((e) => {
          this.loading = false;
      });
    }else{
       BuiltinService.postbloodgroup(this.bloodgroup)
      .then((response) => {
        if(response){
          this.retrieve();
          this.bloodgroup = {title : ""}
              this.loading = false;
        }
      })
      .catch((e) => {
          this.loading = false;
      });
    }
  }
     onFiltered(filteredItems:any) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        // this.rows = filteredItems.length
        // this.currentPage = 1
      }
  
  delete(id: any){
     BuiltinService.deletebloodgroup(id)
      .then((response) => {
        if(response){
          this.retrieve();
          this.bloodgroup = {id: null, title : ""}
            this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
          this.loading = false;
      });
  }

  info(row: any){
    this.bloodgroup['id'] = row['id']; 
    this.bloodgroup['title'] = row['title'];
    this.bloodgroup['user'] = row['user'];
  }
  removeinfo(id: any){
    this.delete(id);
  }
  mounted() {
  this.retrieve();
  }
}
